/* eslint-disable camelcase */
import React from "react";

import * as Sentry from "@sentry/browser";
import { EventEmitter } from "events";
import { OrderedMap } from "immutable";
import ReconnectingWebSocket, { CloseEvent, Event } from "reconnecting-websocket";
import uuid from "uuid";

import * as device from "~/lib/device";
import { debugAssert } from "~/lib/log";
import { validateVersion } from "~/lib/webUpdatePrompt";

import { addBreadcrumb, encodeCurrentPath, isDesktopApp } from "./utils";

function jitter(minimumDelay: number, maxDelay: number) {
	return Math.floor(minimumDelay + (maxDelay - minimumDelay) * Math.random());
}

const PING_INTERVAL = isDesktopApp ? 30000 : 10000;
const MAX_RECONNECT_DELAY = isDesktopApp ? jitter(50000, 60000) : jitter(10000, 20000);
const MIN_RECONNECT_DELAY = isDesktopApp ? jitter(20000, 30000) : jitter(6000, 10000);

type WebsocketReceivedMessageData =
	| {
			type: "REFRESH";
	  }
	| {
			type: "JOINED_GROUP";
			group_name: string;
	  }
	| {
			type: "LEFT_GROUP";
			group_name: string;
			force: boolean;
			code?: number;
	  }
	| {
			type: "PONG";
			timestamp: number;
	  }
	| {
			type: "RESP";
			id: string;
			response: object;
			group_name: string;
	  }
	| {
			type: "ERROR";
			errorType: string;
	  }
	| ({
			type: "VERSION";
			version: string;
	  } & {
			type: string;
	  });

type WebsocketEventListener<T> = (evt: T) => void;
type WebsocketGroupEvents = "online" | "offline" | "close" | "open" | "message" | "leave";

export class WSTimeoutError extends Error {
	constructor(message: string) {
		super(message);
		this.name = "WSTimeoutError";
	}
}

interface RequestPromiseHandler {
	resolve: (v: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
	reject: (e: Error) => void;
	timeout: number | null;
}

export class GroupEmitter {
	online: boolean;
	socketClient: WebsocketClient;
	groupName: string;
	metadata: object;
	emit: (event: WebsocketGroupEvents, ...args: unknown[]) => boolean;
	emitter: EventEmitter;
	requestPromiseHandlers: {
		[key: string]: RequestPromiseHandler | undefined;
	};
	constructor(groupName: string, metadata: object, socketClient: WebsocketClient) {
		this.emitter = new EventEmitter();
		this.emitter.setMaxListeners(100);
		this.groupName = groupName;
		this.metadata = metadata;
		this.online = false;
		this.socketClient = socketClient;

		this.emit = this.emitter.emit.bind(this.emitter);
		this.requestPromiseHandlers = {};

		this.connect();

		this.on("close", () => {
			Object.entries(this.requestPromiseHandlers).forEach(
				([id, handler]) => handler && handler.reject(new Error(`ws request(${id}) failed. Connection closed`)),
			);
		});
	}

	close(): void {
		this.socketClient.onGroupEmitterClose(this);
	}

	connect = (): void => {
		return this._sendJson({
			action: "join",
			metadata: this.metadata,
		});
	};

	send<T = object>(data: T): void {
		return this._sendJson({
			action: "send",
			data,
		});
	}

	request = <T>(data: object, timeout = 10000, retry = 0) => {
		// total timeout is timeout * (retry + 1)
		let retryCount = 0;
		const id = uuid.v4();
		const promise = new Promise<T>((resolve, reject) => {
			this._sendJson({ action: "req", id, data: data, retryCount });
			const handler: RequestPromiseHandler = { resolve, reject, timeout: null };
			this.requestPromiseHandlers[id] = handler;

			if (timeout) {
				const checkTimeout = () => {
					handler.timeout = window.setTimeout(() => {
						if (retryCount++ < retry) {
							this._sendJson({ action: "req", id, data: data, retryCount });
							checkTimeout();
						} else {
							reject(new WSTimeoutError(`ws request(${id}) failed. timeout`));
						}
					}, timeout);
				};
				checkTimeout();
			}
		}).finally(() => {
			delete this.requestPromiseHandlers[id];
		});
		return promise;
	};

	on<T>(event: WebsocketGroupEvents, listener: WebsocketEventListener<T>): EventEmitter {
		return this.emitter.on(event, listener);
	}
	once<T>(event: WebsocketGroupEvents, listener: WebsocketEventListener<T>): EventEmitter {
		return this.emitter.once(event, listener);
	}
	off<T>(event: WebsocketGroupEvents, listener: WebsocketEventListener<T>): void {
		this.emitter.off(event, listener);
		// Prevents a leave and then a join when synchronously replacing a websocket listener.
		setTimeout(() => {
			if (event === "message" && !this.shouldBeJoined()) {
				this._leave();
			}
		}, 0);
	}

	shouldBeJoined(): boolean {
		return this.emitter.listenerCount("message") !== 0;
	}

	reJoin(): void {
		return this._sendJson({
			action: "join",
			metadata: this.metadata,
		});
	}

	_leave(): void {
		return this._sendJson({
			action: "leave",
		});
	}

	_sendJson = (data: object): void => {
		return this.socketClient.sendJson({
			...data,
			group: this.groupName,
		});
	};
}

export default class WebsocketClient {
	emitter: EventEmitter;
	on: EventEmitter["on"];
	off: EventEmitter["off"];
	socketClass: typeof ReconnectingWebSocket;
	groups: Map<string, { refcount: number; emitter: GroupEmitter }>;
	socket: ReconnectingWebSocket | null;
	pingInterval: number | null;
	pingResolvers: OrderedMap<number, (online?: boolean) => void>;
	missedPingsThreshold: number;
	connected: boolean;
	online: boolean;
	deviceID: Promise<string>;

	static instance: WebsocketClient | null = null;

	static getInstance = (socketClass: typeof ReconnectingWebSocket = ReconnectingWebSocket) => {
		WebsocketClient.instance = WebsocketClient.instance || new WebsocketClient(socketClass);
		return WebsocketClient.instance;
	};

	constructor(socketClass = ReconnectingWebSocket) {
		debugAssert(
			!WebsocketClient.instance,
			"Creating multiple instances of WebsocketClient is discouraged, use WebsocketClient.getInstance().",
		);

		this.emitter = new EventEmitter();
		this.on = this.emitter.on.bind(this.emitter);
		this.off = this.emitter.off.bind(this.emitter);

		this.socketClass = socketClass;
		this.groups = new Map();
		this.socket = null;

		this.pingInterval = null;
		this.pingResolvers = OrderedMap([]);
		this.missedPingsThreshold = Number(new Date());

		this.connected = false;
		this.online = false;
		this.connect();
		this.deviceID = device.getIdentifier();

		window.addEventListener("focus", () => this.ping());
	}

	connect = () => {
		const websocketUri = `${window.location.protocol == "https:" ? "wss" : "ws"}://${window.location.host}/ws`;
		this.socket = new this.socketClass(websocketUri, undefined, {
			maxReconnectionDelay: MAX_RECONNECT_DELAY,
			minReconnectionDelay: MIN_RECONNECT_DELAY,
		});
		if (!this.socket) return;
		this.socket.addEventListener("open", this.onOpen);
		this.socket.addEventListener("close", this.onClose);
		this.socket.addEventListener("message", this.onMessage);
	};

	isActive() {
		return document.visibilityState === "visible";
	}

	_onOpen(deviceId: string | null) {
		this.sendJson({
			client_session_id: window.CLIENT_SESSION_ID,
			device_id: deviceId,
			active: this.isActive(),
		});
	}

	onOpen = (_e: Event) => {
		this.connected = true;
		this.online = true;
		this.emitter.emit("open");
		this.emitter.emit("online");
		const connectionTime = Number(Date.now());

		this.deviceID
			.then(identifier => this._onOpen(identifier))
			.catch(e => {
				console.warn("Failed to get device identifier", e);
				this._onOpen(null);
			})
			.then(() => [...this.groups.values()].forEach(group => group.emitter.reJoin()));

		if (this.pingInterval) clearInterval(this.pingInterval);
		this.pingInterval = window.setInterval(this.ping, PING_INTERVAL);
		this.pingResolvers = this.pingResolvers.filter((_v, k) => Boolean(k && k > connectionTime)).toOrderedMap();
		this.ping();
	};

	onClose = (_e: CloseEvent) => {
		this.emitter.emit("close");
		this.connected = false;
		return [...this.groups.values()].forEach(group => group.emitter.emit("close"));
	};

	onMessage = (e: MessageEvent) => {
		const payload = JSON.parse(e.data);
		const { group: groupName, data } = payload;
		if (groupName === "ws") {
			this.onSystemMessage(data);
			return;
		}
		const group = this.groups.get(groupName);
		if (!group) {
			this.leaveGroup(groupName);
			return;
		}
		group.emitter.emit("message", data);
		return;
	};

	onSystemMessage = (data: WebsocketReceivedMessageData) => {
		if (data.type === "REFRESH") {
			window.location.reload();
		}
		if (data.type === "VERSION") {
			if (!validateVersion(data.version, true)) {
				if (this.socket) {
					// If the versions are different, close the socket.
					// Now that the socket is closed, the client will NOT try to reconnect
					// since doing this is pointless. We know that the versions will not match until
					// the client is refreshed.

					// this will set readyState from WebSocket.OPEN to WebSocket.CLOSING and then WebSocket.CLOSED
					this.socket.close();
				}
			}
		}
		let group;
		if (data.type === "JOINED_GROUP") {
			group = this.groups.get(data.group_name);
			if (!group) {
				this.leaveGroup(data.group_name);
				return;
			}
			group.emitter.emit("open");
			if (!group.emitter.online) {
				group.emitter.online = true;
				group.emitter.emit("online");
			}
		}
		if (data.type === "LEFT_GROUP") {
			group = this.groups.get(data.group_name);
			if (group) {
				if (!data.force && group.emitter.shouldBeJoined()) {
					group.emitter.reJoin();
				} else {
					group.emitter.emit("leave", data.code);
					this.groups.delete(data.group_name);
				}
			}
		}
		if (data.type === "PONG" && data.timestamp) {
			const now = Number(Date.now());
			const resolver = this.pingResolvers.get(data.timestamp);
			// if this pong is from too long ago, ignore it
			if (resolver && now - data.timestamp < PING_INTERVAL * 2) {
				resolver(true);
			}
			this.pingResolvers = this.pingResolvers.filter((_v, k) => Boolean(k && k > now)).toOrderedMap();
		}
		if (data.type === "RESP") {
			group = this.groups.get(data.group_name);
			if (!group) return;
			const promiseHandler = group.emitter.requestPromiseHandlers[data.id];
			if (!promiseHandler) return;
			if (promiseHandler.timeout) clearTimeout(promiseHandler.timeout);
			return promiseHandler.resolve(data.response);
		}
		if (data.type === "ERROR") {
			if (data.errorType === "NotAuthed") {
				this.socket && this.socket.removeEventListener("message", this.onMessage);
				this.destroy();
				return window.location.replace(`/auth/login/?next=${encodeCurrentPath()}`);
			}
		}
	};

	updateGroupMetadata = (groupName: string, override: boolean, metadata: object) => {
		const groupMeta = this.groups.get(groupName);
		if (groupMeta) {
			if (override) {
				groupMeta.emitter.metadata = metadata;
			} else {
				groupMeta.emitter.metadata = {
					...metadata,
					...(groupMeta.emitter.metadata || {}),
				};
			}
		}
	};

	getOrJoinGroup = (groupName: string, metadata?: object): GroupEmitter => {
		const groupMeta = this.groups.get(groupName);
		if (groupMeta) {
			groupMeta.refcount += 1;
			return groupMeta.emitter;
		}
		return this.joinGroup(groupName, metadata);
	};

	onGroupEmitterClose(emitter: GroupEmitter): void {
		const meta = this.groups.get(emitter.groupName);
		debugAssert(meta, `Closing rogue GroupEmitter for '${emitter.groupName}`);
		if (meta) {
			meta.refcount -= 1;
			if (meta.refcount <= 0) {
				debugAssert(meta.refcount === 0, `GroupEmitter '${emitter.groupName}' closed too many times`);
				this.leaveGroup(emitter.groupName);
				this.groups.delete(emitter.groupName);
			}
		}
	}

	joinGroup = (groupName: string, metadata: object = {}): GroupEmitter => {
		const groupEmitter = new GroupEmitter(groupName, metadata, this);
		debugAssert(!this.groups.has(groupName), `joinGroup called for already-joined group '${groupName}'`);
		this.groups.set(groupName, { refcount: 1, emitter: groupEmitter });
		return groupEmitter;
	};

	leaveGroup = (groupName: string) => {
		return this.sendJson({
			action: "leave",
			group: groupName,
		});
	};

	sendJson = (data: object) => {
		if (this.socket && this.socket.readyState !== WebSocket.OPEN) return;
		this.socket && this.socket.send(JSON.stringify(data));
		return;
	};

	destroy = (code?: number, reason?: string) => {
		[...this.groups.values()].forEach(group => this.leaveGroup(group.emitter.groupName));
		this.socket && this.socket.close(code, reason);
	};

	reconnect = (code: number, reason: string) => {
		return this.socket != null ? this.socket.reconnect(code, reason) : undefined;
	};

	ping = () => {
		const ts = Number(Date.now());
		const pingPromise = new Promise((resolve, reject) => {
			this.pingResolvers = this.pingResolvers.set(ts, resolve);
			this.deviceID
				.then(device_id =>
					this.sendJson({
						group: "ws",
						action: "ping",
						timestamp: ts,
						device_id,
						active: this.isActive(),
					}),
				)
				.catch(reject);
			// timed rejection if not resolved within PING_INTERVAL * 2
			setTimeout(() => {
				if (Number(Date.now()) > ts + PING_INTERVAL * 2 * 2) {
					// If this call is delayed for too long (twice as scheduled delay),
					// probably recovering from sleeping/hibernation.
					// Ignore the rejection. Resolve as unknown status
					resolve(undefined);
					return;
				}
				if (this.pingResolvers.get(ts)) {
					reject(new WSTimeoutError("ping timed out"));
				} else {
					// Resolved already or
					// the resolver of this promise is gone, a ping after this one has been resolved
					// ignore this rejection.
					// Resolve as unknown status
					resolve(undefined);
				}
			}, PING_INTERVAL * 2);
		});
		return pingPromise
			.then(online => {
				// online might be undefined(unknown).
				if (online === true && !this.online) {
					this.online = true;
					this.emitter.emit("online");
					addBreadcrumb({ category: "ws", message: "online", level: "info" as Sentry.SeverityLevel });
					[...this.groups.values()].forEach(group => {
						if (!group.emitter.online) {
							group.emitter.online = true;
							group.emitter.emit("online");
						}
					});
				}
				return Promise.resolve(online ? "online" : "unknown");
			})
			.catch(({ message }) => {
				if (this.online) {
					const isSocketOpen = this.socket && this.socket.readyState === WebSocket.OPEN;
					this.online = false;
					this.emitter.emit("offline");
					[...this.groups.values()].forEach(group => {
						group.emitter.online = false;
						group.emitter.emit("offline");
					});
					addBreadcrumb({ category: "ws", message: "offline", level: "info" as Sentry.SeverityLevel });
					if (isSocketOpen) {
						// We only want to reconnect the websocket if it is open.
						// See comment next to validateVersion.
						this.reconnect(4000, `Server failed to respond to a ping. client exception message: ${message}`);
					}
				}
				return Promise.resolve("offline");
			});
	};
}

export function useGroupEmitter(name: string): GroupEmitter;
export function useGroupEmitter(name: string | undefined): GroupEmitter | null;

export function useGroupEmitter(name: string | undefined): GroupEmitter | null {
	// Using a useState callback to avoid incrementing the group refcount more
	// than once.
	const [emitter, setEmitter] = React.useState(() =>
		name == null ? null : WebsocketClient.getInstance().getOrJoinGroup(name),
	);

	React.useEffect(() => {
		return () => emitter?.close();
	}, [emitter]);

	React.useEffect(() => {
		if (name !== emitter?.groupName) {
			// Cleanup of the old emitter happens from the other useEffect
			setEmitter(name == null ? null : WebsocketClient.getInstance().getOrJoinGroup(name));
		}
	}, [name, emitter]);

	return emitter;
}
